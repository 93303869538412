import { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import { LocationContext } from "providers/LocationProvider";
import { FadeInRouter } from "components/FadeInRouter";
import { LoggedRoutes } from "pages/LoggedRoutes";
import { setupIonicReact } from "@letsbit/milkylb";
import dayjs from "dayjs";

setupIonicReact();
dayjs.locale("es");

const AccountsRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/AccountsRoutes")).AccountsRoutes,
}));
const LogInRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/LogInRoutes")).LogInRoutes,
}));
const SignUpRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/SignUpRoutes")).SignUpRoutes,
}));
const DefaultRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/DefaultRoutes")).DefaultRoutes,
}));
const SessionExpiredRoute = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/LogInRoutes/SessionExpired"))
    .SessionExpired,
}));
const ErrorRoute = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/ErrorRoutes")).ErrorRoutes,
}));

function App() {
  const { displayLocation } = useContext(LocationContext);
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Routes location={displayLocation}>
        <Route
          path="/accounts/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/accounts"
            >
              <AccountsRoutes />
            </FadeInRouter>
          }
        />
        <Route
          path="/signup/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/signup"
            >
              <SignUpRoutes />
            </FadeInRouter>
          }
        />
        <Route path="/login/*" element={<LogInRoutes />} />
        <Route path="/logged/*" element={<LoggedRoutes prefix="/logged" />} />
        <Route path="/session-expired" element={<SessionExpiredRoute />} />
        <Route path="/error" element={<ErrorRoute />} />

        <Route path="*" element={<DefaultRoutes />} />
      </Routes>
    </Suspense>
  );
}

export default App;
