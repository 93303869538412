import {
  UserWithFullInfo,
  LoginForm,
  USER_STATE,
  PERSON_TYPES,
  EVENT_TRACKER_EVENT_NAMES,
  LoginMethods,
  UserWithSessionInfo,
} from "@letsbit/malcolmlb";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiContext } from "./ApiProvider";
import { createVoidContext } from "../utils/voidContext";
import { useEventTracking } from "../hooks/event_tracking/useEventTracking";
import { useNotification } from "../hooks/utils/useNotification";
import { useLocalStorage } from "../hooks/utils/useLocalStorage";
import { CSRFContext } from "./CSRFProvider";
import { LocaleContext } from "./LocaleProvider";
import { NotificationStatus } from "../components/Notification/types";
import { errorParser, onErrorHandler } from "../utils/defaultError";
// import { useError } from "hooks/utils/useError";

export interface UserData {
  country_residence?: string;
  preferred_currency?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  referrer?: string;
  hdyhau?: string;
  person_type?: PERSON_TYPES;
  investment_notifications?: Record<string, string>;
}

export enum USER_ACCOUNT_STATUS {
  PENDING = "pending",
  ACTIVE = "active",
  BANNED = "banned",
}

export type User = Omit<UserWithFullInfo, "data"> & {
  data: UserData;
  otpAvailable: boolean;
  enrollRequired: boolean;
};

type UserContext = {
  user?: User | null;
  getUser: (redirectPath?: string) => Promise<void>;
  getUserWithoutRedirect: () => Promise<void>;
  loading: boolean;
  logout: (redirectPath?: string) => Promise<void>;
  login: (form: LoginForm, redirectPath: string) => Promise<void>;
  updateUserData: (userData: UserData) => Promise<void>;
  checkSession: (onFinished?: () => void) => Promise<void>;
};

export const UserContext = createContext<UserContext>(
  createVoidContext("user"),
);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { publicInstance, memberInstance } = useContext(ApiContext);
  const { setCSRFToken } = useContext(CSRFContext);
  const eventTracker = useEventTracking();
  // const { addError } = useError();
  const { showNotification } = useNotification();

  const [user, setUser] = useState<User | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { restoreIPValidLocale } = useContext(LocaleContext);

  const { clear: clearLocalStorage } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPathRef = useRef<string>(
    `${location.pathname}${location.search}`,
  );

  // useEffect(() => {
  //   const isUserPending = userState === "pending";
  //   if (uid && !isUserPending) {
  //     navigate(redirectPathRef.current);
  //   }
  // }, [uid, userState]);

  const updateUserData = useCallback(
    async (data: UserData) => {
      try {
        const newData = !user?.data ? data : Object.assign(user?.data, data);

        const [{ data: userUpdated }, { data: userInfo }] = await Promise.all([
          memberInstance.barong.updateUserData(
            JSON.stringify(newData).replace(/</g, "\\u003c"),
          ),
          memberInstance.hodor.getMe(),
        ]);

        setUser(parseUser(userUpdated, userInfo));
      } catch (error) {
        console.error(error);
      }
    },
    [memberInstance, user],
  );

  const logout = useCallback(
    async (redirectPath = "/login") => {
      try {
        await memberInstance.hodor.logout(true);

        sessionStorage.clear();
        setUser(null);
        clearLocalStorage();

        navigate(redirectPath);

        restoreIPValidLocale();
      } catch (error) {
        console.error(error);
        // addError(
        //   "Oups! Algo salió mal, intenta de nuevo mas tarde",
        //   "FAILED_USER_LOGOUT"
        // );
      }
    },
    [memberInstance.hodor, clearLocalStorage, navigate, restoreIPValidLocale],
  );

  const parseUser = (
    userResponse: UserWithFullInfo,
    userSession: UserWithSessionInfo,
  ): Omit<UserWithFullInfo, "data"> & {
    data: UserData;
    otpAvailable: boolean;
    enrollRequired: boolean;
  } => {
    const parsedData: UserData | null = JSON.parse(userResponse.data);
    const defaultedData = {
      ...parsedData,
      country_residence:
        parsedData && "country_residence" in parsedData
          ? parsedData.country_residence
          : "",
      preferred_currency:
        parsedData && "preferred_currency" in parsedData
          ? parsedData.preferred_currency
          : "pax",
    };

    return {
      ...userResponse,
      data: defaultedData,
      otpAvailable: userSession.otp_available,
      enrollRequired:
        !userSession.otp_available && userSession.session.require_enroll,
    };
  };

  const onSuccessAuth = useCallback(
    async (
      user: UserWithFullInfo,
      userInfoSession: UserWithSessionInfo,
      redirectPath?: string,
    ) => {
      if (user.state === USER_STATE.PENDING) {
        // addError({
        //   title: "Tu cuenta no está activada",
        //   message: "Te enviaremos un mail para que la actives.",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta no está activada",
          message: "Te enviaremos un mail para que la actives.",
          status: NotificationStatus.WARNING,
        });

        logout(`/signup/success?email=${encodeURIComponent(user.email)}`);
      } else if (user.state === USER_STATE.BANNED) {
        // addError({
        //   title: "Tu cuenta está baneada",
        //   message: "Contactanos al chat",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta está baneada",
          message: "Contactanos al chat",
          status: NotificationStatus.WARNING,
        });

        logout();
      } else if (user.state === USER_STATE.LOCKED) {
        navigate("/login/locked");
      } else if (
        user.state === USER_STATE.RESTRICTED ||
        user.state === USER_STATE.FROZEN ||
        user.state === USER_STATE.RETRIEVED
      ) {
        navigate("/login/revision");
      } else {
        const parsedUser = parseUser(user, userInfoSession);
        setUser(parsedUser);

        if (user.level < 3) {
          // TODO: pantalla hablar con sol para activar la cuenta
        } else {
          if (user.role === "partner") {
            navigate(redirectPath || redirectPathRef.current, {
              replace: true,
            });
          } else {
            showNotification({
              title: "Tu cuenta no es partner",
              message:
                "La cuenta con la que iniciaste sesión no es una cuenta partner. Contactanos al chat",
              status: NotificationStatus.ERROR,
            });
            logout();
          }
        }
      }
    },
    [
      logout,
      memberInstance.compliance,
      navigate,
      showNotification,
      updateUserData,
    ],
  );

  const getUser = useCallback(
    async (redirectPath?: string) => {
      setLoading(true);
      try {
        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
        ]);

        await onSuccessAuth(user, data, redirectPath);

        // TODO: Check if we stil want to use Sentry as a logging provider for frontend. Check options
        // SentrySetUser({ id: userResponse.uid });
      } catch (error) {
        navigate("/login");
        setUser(null);
        console.error(error);
      }
      setLoading(false);
    },
    [memberInstance.barong, memberInstance.hodor, navigate, onSuccessAuth],
  );

  const getUserWithoutRedirect = useCallback(async () => {
    setLoading(true);
    try {
      const [{ data: user }, { data }] = await Promise.all([
        memberInstance.barong.getUser(),
        memberInstance.hodor.getMe(),
      ]);

      const parsedUser = parseUser(user, data);
      setUser(parsedUser);

      // TODO: Check if we stil want to use Sentry as a logging provider for frontend. Check options
      // SentrySetUser({ id: userResponse.uid });
    } catch (error) {
      setUser(null);
      console.error(error);
    }
    setLoading(false);
  }, [memberInstance.barong, memberInstance.hodor]);

  const login = useCallback(
    async (form: LoginForm, redirectPath: string): Promise<void> => {
      const onSuccess = async (
        user: UserWithFullInfo,
        userSessionInfo: UserWithSessionInfo,
      ): Promise<void> => {
        await onSuccessAuth(user, userSessionInfo, redirectPath);

        eventTracker.identify(user.uid, { email: user.email });
        eventTracker.capture(EVENT_TRACKER_EVENT_NAMES.USER_LOGGED_IN, {
          method: "password",
        });

        // SentrySetUser({ id: user.uid });

        // TODO: This probably will blowout with the integration of mixpanel
        // TagManager.dataLayer({ dataLayer: { event: "user_logged" } });
        // TagManager.dataLayer({
        //   dataLayer: { event: "userIdSet", userId: user.uid },
        // });
      };

      const onError = onErrorHandler((message) => {
        switch (message) {
          case "identity.invalid_credentials":
          case "identity.session.invalid_params":
          case "identity.session.invalid_login_params":
            showNotification({
              title: "Datos incorrectos",
              message: "Mail y/o contraseña incorrectos.",
              status: NotificationStatus.ERROR,
            });
            break;
          case "identity.session.banned":
            navigate("/login/banned");
            break;
          case "identity.session.locked":
            navigate("/login/locked");
            break;
          case "identity.session.restricted":
          case "identity.session.frozen":
          case "identity.session.retrieved":
            navigate("/login/revision");
            break;
          default:
            // addError();
            break;
        }
      });

      try {
        setUser(null);
        setLoading(true);

        const { data: flow } = await publicInstance.hodor.loginBrowserBegin({
          method: LoginMethods.PASSWORD,
          captcha_response: form.captcha_response,
        });

        const { data: session } = await publicInstance.hodor.loginFinish(
          flow.id,
          {
            email: form.email,
            password: form.password,
          },
        );

        if (session.csrf_token) {
          setCSRFToken(session.csrf_token);
        }

        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
        ]);

        await onSuccess(user, data);
      } catch (error: unknown) {
        onError(error);
      } finally {
        setLoading(false);
      }
    },
    [
      eventTracker,
      memberInstance.barong,
      memberInstance.hodor,
      navigate,
      onSuccessAuth,
      publicInstance.hodor,
      setCSRFToken,
      showNotification,
    ],
  );

  const checkSession = useCallback(
    async (onFinished?: () => void) => {
      try {
        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
        ]);

        await onSuccessAuth(user, data, "/logged/dashboard");
        onFinished?.();
      } catch (e) {
        if (!(errorParser(e) === "authz.pending_authorization")) {
          onFinished?.();
        }
        console.error(e);
      }
    },
    [memberInstance.barong, memberInstance.hodor, onSuccessAuth],
  );

  return (
    <UserContext.Provider
      value={{
        updateUserData,
        user,
        getUser,
        getUserWithoutRedirect,
        loading,
        logout,
        login,
        checkSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
