import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { IntlProvider } from "react-intl";
import { ErrorProvider } from "providers/ErrorProvider";
import NotificationsProvider from "providers/NotificationsProvider";
import LocationProvider from "providers/LocationProvider";
import LocaleProvider from "providers/LocaleProvider";
import { ApiProvider } from "providers/ApiProvider";
import { UserProvider } from "providers/UserProvider";
import { IonApp } from "@ionic/react";
import { PHProvider } from "providers/PHProvider";
import CSRFProvider from "providers/CSRFProvider";
import { BrowserRouter } from "react-router-dom";

import "./assets/styles/global.scss";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <PHProvider>
      <BrowserRouter>
        <IntlProvider locale={"es-AR"} defaultLocale="es-AR">
          <ErrorProvider>
            <NotificationsProvider>
              <LocationProvider>
                <LocaleProvider>
                  <CSRFProvider>
                    <ApiProvider>
                      <UserProvider>
                        <IonApp className="ion-root">
                          <App />
                        </IonApp>
                      </UserProvider>
                    </ApiProvider>
                  </CSRFProvider>
                </LocaleProvider>
              </LocationProvider>
            </NotificationsProvider>
          </ErrorProvider>
        </IntlProvider>
      </BrowserRouter>
    </PHProvider>
  </StrictMode>,
);
