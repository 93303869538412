import { lazy, Suspense, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import { UserContext } from "providers/UserProvider";
import { FadeInRouter } from "components/FadeInRouter";
import { LoggedRoutesProps } from "./types";
import { NavBar } from "./NavBar";
import { LocationContext } from "providers/LocationProvider";
import classNames from "classnames";
import IdleTimer from "components/IdleTimer";

const DashboardRoutes = lazy(async () => ({
  default: (await import("./DashboardRoutes")).DashboardRoutes,
}));
const BalanceRoutes = lazy(async () => ({
  default: (await import("./BalanceRoutes")).BalanceRoutes,
}));
const ProfileRoutes = lazy(async () => ({
  default: (await import("./ProfileRoutes")).ProfileRoutes,
}));
const HistoryRoutes = lazy(async () => ({
  default: (await import("./HistoryRoutes")).HistoryRoutes,
}));
const MarketPricesRoutes = lazy(async () => ({
  default: (await import("./MarketPricesRoutes")).MarketPricesRoutes,
}));

export const LoggedRoutes: React.FC<LoggedRoutesProps> = ({ prefix }) => {
  const { user, loading, getUser } =
    useContext(UserContext);
  const { displayLocation } = useContext(LocationContext);

  useEffect(() => {
    if (user === undefined && !loading) {
      getUser();
    }
  }, [getUser, loading, user]);

  if (loading && user === undefined) {
    return <LoadingAnimation />;
  }

  // TODO: Hay que revisar fuertemente cuando ya sabemos que un usuario esta logueado y cuando no.
  // if (!loading && !user && !auth0isAuthenticated) {
  //   return <Navigate to="/login" replace />;
  // }

  const shouldDisplayOnboarding =
    !displayLocation.pathname.startsWith("/logged/onboarding");

  return (
    <div className="flex h-full">
      {shouldDisplayOnboarding && <NavBar />}
      <IdleTimer />
      <FadeInRouter
        className={classNames(
          "flex min-w-0 grow flex-col",
          !displayLocation.pathname.startsWith("/logged/dashboard") &&
            shouldDisplayOnboarding &&
            "pb-10 pl-5 pr-10 pt-10",
        )}
        prefix={prefix}
      >
        <Suspense fallback={<LoadingAnimation />}>
          <Routes>
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="/balance/*" element={<BalanceRoutes />} />
            <Route path="/profile/*" element={<ProfileRoutes />} />
            <Route path="/history/*" element={<HistoryRoutes />} />
            <Route path="/market-prices/*" element={<MarketPricesRoutes />} />
            <Route
              path="*"
              element={<Navigate to="/logged/dashboard" replace={true} />}
            />
          </Routes>
        </Suspense>
      </FadeInRouter>
    </div>
  );
};
