import React, {
  useState,
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTransition, animated } from "@react-spring/web";
import { Notification as NotificationType } from '../components/Notification/types';
import { createVoidContext } from "../utils/voidContext";
import { Notification } from "../components/Notification";

let id = 0;

interface NotificationContext {
  notifications: NotificationType[];
  addNotification: (notification: NotificationType) => void;
}

export const NotificationContext = React.createContext<NotificationContext>(
  createVoidContext("notification-context")
);

export type Props = {
  children: ReactNode;
};

const NotificationsProvider = (props: Props) => {
  const { children } = props;
  const [notifications, setNotifications] = useState<
    (NotificationType & { key: number })[]
  >([]);
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const timeout = 3000;
  const config = { tension: 125, friction: 20, precision: 0.0001 };

  const transitions = useTransition(notifications, {
    from: { opacity: 0, height: 0, life: "100%", width: 414, x: 50 },
    keys: (item) => item.key,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({
        opacity: 1,
        // This + 16 is for the margin top of the element
        height: refMap.get(item).offsetHeight + 16,
        x: 0,
      });
      await next({ life: "0%" });
    },
    leave: [{ x: 50, opacity: 0 }, { height: 0 }],
    onRest: (_result, _ctrl, item) => {
      setNotifications((state) =>
        state.filter((i) => {
          return i.key !== item.key;
        })
      );
    },
    config: (_item, _index, phase) => (key) =>
      phase === "enter" && key === "life" ? { duration: timeout } : config,
  });

  const addNotification = useCallback((notification: NotificationType) => {
    setNotifications((notifications) => [
      ...notifications,
      { ...notification, key: id++ },
    ]);
  }, []);

  const contextValue = {
    notifications,
    addNotification,
  };

  useEffect(() => {
    if (notifications.length === 0) {
      id = 0;
    }
  }, [notifications]);

  return (
    <NotificationContext.Provider value={contextValue}>
      <div className="fixed bottom-8 right-8 z-50 flex flex-col items-end">
        {transitions(({ life, ...style }, item) => (
          <animated.div style={style}>
            <Notification
              className="mt-4"
              title={item.title}
              message={item.message}
              status={item.status}
              onCancel={(e) => {
                e.stopPropagation();
                if (cancelMap.has(item) && life.get() !== "0%")
                  cancelMap.get(item)();
              }}
              forwardedRef={(ref: HTMLDivElement) => {
                if(ref) {
                  refMap.set(item, ref);
                }
              }}
            />
          </animated.div>
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationsProvider;
