import React, { ReactNode } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { createVoidContext } from "../utils/voidContext";

export type Props = {
  children: ReactNode;
  navigate: NavigateFunction;
};

interface ErrorContext {
  error: Error | null;
  resetError: () => void;
}

export const ErrorContext = React.createContext<ErrorContext>(
  createVoidContext("error-context"),
);

const withNavigation = (Component: React.ComponentType<any>) => {
  return (props: any) => <Component {...props} navigate={useNavigate()} />;
};

class ErrorProviderComponent extends React.PureComponent<
  Props,
  {
    error: Error | null;
    hasError: boolean;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      hasError: false,
    };
  }

  // static getDerivedStateFromError() {
  //   return { hasError: true };
  // }

  componentDidCatch(error: Error) {
    this.setState({
      error,
    });
    this.props.navigate("/error");

    return true;
  }

  render() {
    return (
      <ErrorContext.Provider
        value={{
          error: this.state.error,
          resetError: () =>
            this.setState({
              error: null,
            }),
        }}
      >
        <>{this.props.children}</>
      </ErrorContext.Provider>
    );
  }
}

export const ErrorProvider = withNavigation(ErrorProviderComponent);
