import React, { useState, ReactNode } from "react";
import { createVoidContext } from "../utils/voidContext";

interface LocaleContext {
  locale: string;
  setLocale: (locale: string) => void;
  restoreIPValidLocale: () => void;
}

export const LocaleContext = React.createContext<LocaleContext>(
  createVoidContext("locale-context")
);

export type Props = {
  children: ReactNode;
};

const LocaleProvider = (props: Props) => {
  const { children } = props;

  const location = document.documentElement.getAttribute("data-country");
  const [locale, setLocale] = useState<string>(location || "");
  const restoreIPValidLocale = () => setLocale(location || "");

  const contextValue = {
    locale,
    setLocale,
    restoreIPValidLocale,
  };

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
